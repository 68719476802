import { AxiosRequestConfig } from 'axios'

import {
  MaxioCustomerInitilizer,
  MaxioLoggerSubscription,
  UpdateLoggerSubscription,
} from '@beaded/models'

import type { Obj } from 'types/common'

import * as apiUtils from '.'

const url = '/maxio'

export const getSecurityToken = () => apiUtils.GET(`${url}/generate-token`)

export const bulkCreateSubscription = (data: Obj) =>
  apiUtils.POST(`${url}/subscriptions/bulkCreate`, data)

// Subscriptions
export const createSubscription = (data: Obj) =>
  apiUtils.POST(`${url}/subscriptions/create`, data)
export const getAllSubscriptions = (
  options: AxiosRequestConfig = {},
): Promise<MaxioLoggerSubscription[]> =>
  apiUtils.GET(`${url}/subscriptions/getAll`, options)
export const updateSubscription = (data: UpdateLoggerSubscription) =>
  apiUtils.PUT(`${url}/subscriptions/update`, data)
export const getDuplicateSubscriptions = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/subscriptions/duplicates`, options)

// Customers
export const createCustomer = (data: MaxioCustomerInitilizer) =>
  apiUtils.POST(`${url}/customers/create`, data)
export const updateCustomer = (data: Obj) =>
  apiUtils.PUT(`${url}/customers/update`, data)

// Products
export const getAllProducts = () => apiUtils.GET(`${url}/products/getAll`)
export const getCustomerPaymentProfiles = (
  customerId: number,
  data: AxiosRequestConfig = {},
) =>
  apiUtils.GET(`${url}/paymentProfile/getCustomerProfile/${customerId}`, data)

// Coupons
export const getAllCoupons = () => apiUtils.GET(`${url}/coupons/getAll`)

// Subscribed Loggers
export const createLoggerSubscriptionAssignment = (data: Obj) =>
  apiUtils.POST(`${url}/subscriptions/logger/create`, data)
export const removeLoggerSubscriptionAssignment = (data: Obj) =>
  apiUtils.PUT(`${url}/subscriptions/logger/remove`, data)
