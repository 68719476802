import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react'
import { createContext, useContext } from 'react'

import { useDraggable } from 'hooks/useDraggable'

export interface IModalContainer {
  TriggerButton: ({ onClick }: { onClick: Function | any }) => JSX.Element
  children: React.ReactElement | React.ReactElement[]
  modalProps?: any
  modalBodyProps?: any
  header?: string | React.ReactElement
  footer?:
    | string
    | React.ReactElement
    | (({ onClose }: { onClose: Function | any }) => JSX.Element)
  disclosureProps?: UseDisclosureProps
}

const ModalContext = createContext({
  isOpen : false,
  onOpen : () => {},
  onClose: () => {},
})

export function ProvideModalContext({
  children,
  disclosureProps,
}: {
  children: React.ReactNode
  disclosureProps?: UseDisclosureProps
}) {
  return (
    <ModalContext.Provider value={useProvideModalContext(disclosureProps)}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => {
  return useContext(ModalContext)
}

function useProvideModalContext(disclosureProps?: UseDisclosureProps) {
  const { isOpen, onOpen, onClose } = useDisclosure(disclosureProps)

  return {
    isOpen,
    onOpen,
    onClose,
  }
}

const RawModal = ({
  TriggerButton,
  children,
  modalProps,
  modalBodyProps,
  header,
  footer,
}: IModalContainer) => {
  const { isOpen, onOpen, onClose } = useModalContext()
  const { onMouseDown, onMouseMove, onMouseUp, position, elemRef, hasMoved } =
    useDraggable()

  return (
    <>
      <TriggerButton onClick={onOpen} />

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        maxH='100vh'
        isCentered={!hasMoved}
        blockScrollOnMount={false}
        scrollBehavior='inside'
        {...modalProps}
      >
        <ModalOverlay
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseUp}
        />
        <ModalContent
          maxH='100vh'
          marginTop='0'
          marginBottom='0'
          {...position}
          {...(hasMoved ? { position: 'absolute' } : {})}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseUp}
          ref={elemRef}
        >
          {header && (
            <ModalHeader
              onMouseDown={onMouseDown}
              cursor='move'
              style={headerStyle}
            >
              {header}
            </ModalHeader>
          )}
          <ModalCloseButton />
          <ModalBody {...modalBodyProps}>{children}</ModalBody>
          {typeof footer === 'function' ? (
            <ModalFooter style={footerStyle}>{footer({ onClose })}</ModalFooter>
          ) : typeof footer !== 'undefined' ? (
            <ModalFooter style={footerStyle}>{footer}</ModalFooter>
          ) : null}
        </ModalContent>
      </Modal>
    </>
  )
}

export const ModalContainer = ({
  disclosureProps,
  ...rest
}: IModalContainer) => {
  return (
    <ProvideModalContext disclosureProps={disclosureProps}>
      <RawModal {...rest} />
    </ProvideModalContext>
  )
}

const headerStyle = { boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 8px 2px' }
const footerStyle = { boxShadow: 'rgba(0, 0, 0, 0.06) 0 -10px 8px 2px' }
